<template>
    <!--brands-->
    <section class="brands clients-area " id="clients" style="background-color: white;">
        <div class=" pb-66">
        <div class="my-container text-center">
            <VueSlickCarousel v-if="images" v-bind="settings"         >

                <div class="item" v-for="(image, index) in images" :key="index"> 
                    <div class="brand-icon" >
                        <img class="image" :src="image" alt="client">
                    </div>
                </div>

            </VueSlickCarousel>
        </div>
        </div>
    </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    data() {
        return {
            images :[
                "assets/images/clients/murtada-maaz.webp",
                "assets/images/clients/teg.webp",
                "assets/images/clients/mep.webp",
                "assets/images/clients/ikad.webp",
                "assets/images/clients/alwateen.webp",
                "assets/images/clients/tekno-consultancy.webp",
                "assets/images/clients/ampc.webp",
                "assets/images/clients/vision-interiore.webp",
                "assets/images/clients/bamho-contracting.webp",
                "assets/images/clients/dal-group.webp",
                "assets/images/clients/mahgoub-sons.webp",
                "assets/images/clients/corinthia-hotel.webp",
                "assets/images/clients/rotana-alsalam.webp",
                "assets/images/clients/arab-bank.webp",
                "assets/images/clients/zain.webp",
                "assets/images/clients/mtn.webp",
                "assets/images/clients/ram-energy.webp",
                "assets/images/clients/tihraga-engineering.webp",
                "assets/images/clients/rida-group.webp",
                "assets/images/clients/alkhaleej-bank.webp",
                "assets/images/clients/bank-of-khartoum.webp",
                "assets/images/clients/albaraka.webp",
                "assets/images/clients/faisal-islamin-bank.webp",
                "assets/images/clients/aljazeera-sudanese-jordanian-bank.webp",
                "assets/images/clients/wfp.webp",
                "assets/images/clients/alrawabi.webp",
               "assets/images/clients/unicef.webp",
               "assets/images/clients/26daraja.webp",
            ],
             
            settings: {
            "dots": false,
            "arrows": false,
            "infinite": true,
            "rtl": true,
            "autoplay": true,
            "speed": 500,
            "slidesToShow": 7,
            "slidesToScroll": 1,
            "initialSlide": 7,

            "responsive": [
                {
                "breakpoint": 1024,
                "settings": {
                    "slidesToShow": 7,
                    "slidesToScroll": 1,
                    "infinite": true,

                }
                },
                {
                "breakpoint": 600,
                "settings": {
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "initialSlide": 4,
                }
                },
                {
                "breakpoint": 480,
                "settings": {
                    "slidesToShow": 3,
                    "slidesToScroll": 1,
                    
                }
                }
            ]
            }
        }
    },
    components: { VueSlickCarousel },
}
</script>



<style>
    .image {
        width: 120px !important;
        height: 60px;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 65px;
    }

    .slick-slide   {
        widows: 120px !important;
    }

    @media(max-width :1000px) {
        .slick-track{
            height: 100px;
        }

        .slick-slide{
            width:120px;
        }
    }

    /* .brands{
        width: 100%;
        height: auto;
        text-align: center;
        padding: 20px 0;
        position: relative;
    }
    .brand-icon {
        height: 50px;
        width: 100px;
        margin: auto;
    }
    .brands .brand-icon {
    height: 50px;
    width: 100px;
    margin: auto;
} */
</style>
